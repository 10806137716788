<template>
  <v-dialog v-model="show" persistent max-width="500">
    <v-card :loading="loading">
      <v-toolbar dense dark flat tile color="secondary">
        <v-toolbar-title>Ajustes de reparaciones</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="mt-2">
        <v-alert v-if="error" type="error" dense>{{ error }}</v-alert>
        <v-form v-model="isFormValid" ref="form" lazy-validation>
          <v-subheader class="px-0">Próximo folio</v-subheader>
          <p>
            El folio aquí ingresado será el de la siguiente orden de servicio
            creada. Conforme se crean las reparaciones este número se
            actualizará.
          </p>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="form.nextInvoiceId"
                label="Próximo folio"
                outlined
                color="secondary"
                :rules="[$rules.numeric(), $rules.integer()]"
                :append-icon="!!form.nextInvoiceId ? 'mdi-delete' : null"
                @click:append="form.nextInvoiceId = ''"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="d-md-flex justify-end">
              <v-btn
                color="secondary"
                outlined
                class="mr-1 mb-1"
                :block="$vuetify.breakpoint.smAndDown"
                @click="$emit('close')"
              >
                <span>Cerrar</span>
              </v-btn>
              <v-btn
                color="secondary"
                :loading="loading"
                class="mb-1"
                :disabled="!isFormValid"
                :block="$vuetify.breakpoint.smAndDown"
                @click="submit()"
              >
                <v-icon small class="mr-2">mdi-content-save</v-icon>
                <span>Guardar ajustes</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import serverRequestMixin from "@/mixins/serverRequest.mixin";

export default {
  mixins: [serverRequestMixin],

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      error: null,
      loading: false,
      isFormValid: false,
      form: {
        nextInvoiceId: "",
      },
    };
  },

  computed: {
    ...mapGetters(["user"]),
  },

  mounted() {
    this.getBusiness();
  },

  methods: {
    async getBusiness() {
      this.error = null;
      this.loading = true;
      try {
        const response = await this.getRequest(
          `/business/${this.user.businessId}`
        );
        this.loading = false;
        this.form.nextInvoiceId = response.business.nextInvoiceId;
      } catch (error) {
        this.loading = false;
        error.status < 500
          ? (this.error = error.data.message)
          : console.error(error);
      }
    },

    async submit() {
      this.error = null;
      this.loading = true;

      try {
        await this.putRequest(`/business/nextInvoiceId`, this.form);
      } catch (error) {
        this.loading = false;
        error.status < 500
          ? (this.error = error.data.message)
          : console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
